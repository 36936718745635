import React, { useEffect } from "react";
import proProjectList from "../data/proProjectsList";
import useMediaQuery from "../hooks/useMediaQuery";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import ProjectsCarousel from "../pages/home/components/ProProjectsCarousel";
import toolsIcons from "../data/toolkit";

// Images
import bolivarBanner from "../assets/images/Bolivar-LAB.jpg";
import abstractSquaredGrid from "../assets/images/square-squared-grid.png";
import abstractPlusGrid from "../assets/images/abstract-plus-grid-2.png";
import bolivarThreads from "../assets/images/Bolivar.png";
import bolivarThreads1 from "../assets/images/Bolivar-threads-1.png";
import bolivarThreads2 from "../assets/images/Bolivar-threads-2.png";
import bolivarThreads3 from "../assets/images/Bolivar-threads-3.png";
import btProduct1 from "../assets/images/Bolivar-product-1.png";
import btProduct2 from "../assets/images/Bolivar-product-2.png";
import btProduct3 from "../assets/images/Bolivar-product-3.png";
import btProduct4 from "../assets/images/Bolivar-product-4.png";
import btTypography1 from "../assets/images/Bolivar-Typography-1.png";
import btTypography2 from "../assets/images/Bolivar-Typography-2.png";
import btStyles from "../assets/images/Bolivar-Styles.png";
import btColors from "../assets/images/Bolivar-colors.png";
import btButtons from "../assets/images/Bolivar-buttons.png";
import btOrganizingProducts from "../assets/images/Bolivar-Organizing-Products.png";
import btHome from "../assets/images/Bolivar-threads-home.png";
import btShop from "../assets/images/Bolivar-shop.png";
import btProduct from "../assets/images/Bolivar-product-page.png";
import btAbout from "../assets/images/Bolivar-about.png";
import btCustomizeBag from "../assets/images/Bolivar-Customize-Bag.png";
import btTextLayers from "../assets/images/Bolivar-text-layers.png";
import abstractGridDashedLines from "../assets/images/abstract-grid-dashed-lines.png";

export default function Bolivar() {
  // TOOLS ICONS
  const projectTools = [
    "WordPress",
    "Elementor",
    "CSS",
    "Adobe XD",
    "Photoshop",
  ];

  const getToolKitIcons = (tools) => {
    return toolsIcons.filter((icon) => tools.includes(icon.title));
  };

  const projectIcons = getToolKitIcons(projectTools);

  // FOR LAYING DOWN THE UI
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    ScrollTrigger.matchMedia({
      "(min-width: 768px)": function () {
        gsap.to("#layoutImages", {
          scrollTrigger: {
            trigger: "#layoutImages",
            start: "top top",
            // pin: ".project__inner-layout-container",
            end: "bottom bottom",
            scrub: true,
            nullTargetWarn: false,
            // markers: "true",
          },
        });
      },
    });
  }, []);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    ScrollTrigger.matchMedia({
      "(min-width: 768px)": function () {
        gsap.to("#layoutImages", {
          scrollTrigger: {
            trigger: "#layoutImages",
            start: "top top",
            pin: "#layoutContent",
            end: "bottom bottom",
            scrub: true,
            nullTargetWarn: false,
            // markers: "true",
          },
        });
      },
    });
  }, []);

  // FOR CAROUSEL (See Hooks)
  const isMobile = useMediaQuery("(min-width:991px)");

  return (
    <div>
      {/* HEADER */}
      <section className="project__container">
        <img
          className="project__main-image"
          src={bolivarBanner}
          alt="Two women, each with a purse on their lap."
        />
        <img
          className="project__main-image-abstract"
          src={abstractSquaredGrid}
          alt="Abstract. Grid of squares."
        />

        <div className="project__main-info d-flex align-items-center">
          <p className="project__main-project-number">04</p>
          <h1 className="project__main-title project__bolivar-title">
            Bolivar Threads
          </h1>
        </div>
      </section>

      {/* PROJECT OVERVIEW */}
      <div className="project__details-container">
        <div className="row g-5 pb-5">
          <div className="col-12 col-sm-12 col-md-5 col-lg-5">
            <div className="project__details-section">
              <h4>Client</h4>
              <p>Bolivar Threads</p>
            </div>
            <div className="project__details-section">
              <h4>Business Type</h4>
              <p>Monogram eCommerce Shop</p>
            </div>
            <div className="project__details-section">
              <h4>Completed</h4>
              <p>2019</p>
            </div>
            <div className="project__details-section">
              <h4>Status</h4>
              <p>Managed by client</p>
            </div>
          </div>

          <div className="col-12 col-sm-12 col-md-7 col-lg-7">
            <p>
              In Houston, Texas, Bolivar Threads began as a passion project,
              personalizing bags and shirts for family and friends for their
              special events. Soon, demand began to grow and the owner realized
              she was in need of a streamlined process. Taking orders in person
              or over the phone became time-consuming and difficult to track.
            </p>
            <p>
              Bolivar Threads reached out to me to design and develop an
              eCommerce store that would allow customers to quickly select a
              product, customize it, and place an order. It was also important
              for the client to be able to maintain and update the website on
              their own over time.
            </p>
            <p>After several meetings with the client, I began setting up.</p>
          </div>
        </div>

        {/* CONTRIBUTIONS */}
        <div className="row d-flex justify-content-end mt-5 mb-5 project__contribution-container">
          <img
            src={abstractPlusGrid}
            alt="Grid of plus symbol."
            className="project__contribution-image"
          />

          <div className="col-12 col-sm-12 col-md-12 col-lg-9">
            <h4 className="mb-5">Contributions</h4>

            <div className="row g-5 pt-5">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 project__contributions-item">
                <p>UX Research</p>
                <hr />
                <p>UI Design</p>
                <hr />
                <p>Wireframing</p>
                <hr />
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 project__contributions-item">
                <p>Website Design</p>
                <hr />
                <p>WordPress Development</p>
                <hr />
                <p>CSS</p>
                <hr />
              </div>
            </div>

            <h4 className="mt-5 mb-5">Environment & Tools</h4>
            <ul className="project__tool-list-container project__icons-tool-list">
              {projectIcons.map((icon, index) => (
                <img
                  key={index}
                  src={icon.image}
                  alt={icon.title}
                  className="project__tool-icon"
                />
              ))}
            </ul>
          </div>
        </div>

        {/* THE SETUP */}
        <div className="row mt-5 pb-5">
          <h4 className="mb-3">The Set Up</h4>
          <p>
            Once the domain was acquired and hosting established, I installed
            WordPress, a Content Management System. This CMS allows for growth
            and expansion, and because it's open-source, there are a plethora of
            compatible plugins, allowing the company to launch the website in a
            short period of time.
          </p>
          <p>
            Based on the project requirements, I installed and configured
            several plugins that provided the needed built-in functionality.
            This included Elementor Pro Fancy Product Designer, WooCommerce, and
            more.
          </p>
        </div>

        {/* BOLIVAR OVERVIEW */}
        <div className="row">
          <img
            src={bolivarThreads}
            alt="Bolivar word."
            style={{
              width: "70%",
              marginLeft: "-10px",
              marginTop: "-120px",
              marginBottom: "-50px",
            }}
            className="project__bolivar-text-image"
          />

          <div className="col-12 col-sm-12 col-md-6 col-lg-6"></div>
          <div
            className="col-12 col-sm-12 col-md-6 col-lg-6"
            style={{ marginBottom: "150px" }}
          >
            <h4 className="mb-3">Starting from Scratch</h4>
            <p>
              To get a good feel for the clients' business, style, and goals, I
              visited her workspace. Though I am no photographer, I took a few
              pictures of her equipment for design inspiration.
            </p>
          </div>
        </div>

        {/* IMAGES OF THREADS */}
        <div
          className="project__bolivar-threads-images"
          style={{ position: "relative" }}
        >
          <img
            src={bolivarThreads1}
            alt="Side view of thread spools. Colors: gold, pink, white."
            style={{
              position: "absolute",
              width: "40%",
              left: "-2vw",
              top: "-15vh",
              boxShadow: "rgb(0 0 0 / 22%) 0px 3px 50px",
              zIndex: "0",
            }}
          />

          <img
            src={bolivarThreads2}
            alt="Top view of thread spools. Colors: red, pink, burgandy, yellow, white."
            style={{
              position: "absolute",
              left: "20vh",
              zIndex: "30",
              width: "70vw",
              boxShadow: "rgb(0 0 0 / 22%) 0px 3px 50px",
            }}
          />

          <img
            src={bolivarThreads3}
            alt="Monogram machine."
            style={{
              position: "absolute",
              top: "30vh",
              zIndex: "20",
              width: "60vw",
              boxShadow: "rgb(0 0 0 / 22%) 0px 3px 50px",
            }}
          />
        </div>

        {/* PRODUCT PICTURES */}
        <div className="row mt-5 mb-5 project__bolivar-products">
          <h4 className="mb-3">Getting to know the Products</h4>
          <p>
            Good product images go a long way. With no photographer at hand, I
            took a few pictures of the products available for sale and
            photoshopped the background, to give them a clean, cohesive
            appearance.
          </p>

          <div className="col-12 col-sm-12 col-md-6 col-lg-5"></div>
        </div>

        {/* --images-- */}
        <div className="row d-flex g-3 pb-5 align-contents-end">
          <div className="col-6 col-md-3 col-lg-3">
            <img
              src={btProduct1}
              alt="Laptop back with the word computer and charger on the front."
              style={{ width: "100%", height: "auto" }}
            />
          </div>
          <div className="col-6 col-md-3 col-lg-3">
            <img
              src={btProduct2}
              alt="Pouch with the words Wet Beach Stuff."
              style={{ width: "100%", height: "auto" }}
            />
          </div>
          <div className="col-6 col-md-3 col-lg-3">
            <img
              src={btProduct3}
              alt="Pouch with the words My Toiletry."
              style={{ width: "100%", height: "auto" }}
            />
          </div>
          <div className="col-6 col-md-3 col-lg-3">
            <img
              src={btProduct4}
              alt="Beauty box with a black bow."
              style={{ width: "100%", height: "auto" }}
            />
          </div>
        </div>

        {/* STYLE GUIDE */}
        <div className="pt-5 pb-5 mt-5 project__style-guide-box">
          <div id="desc">
            <h4 className="mb-3">Style Guide</h4>
            <p>
              For the color pallete, I went with colors that were both bold and
              soft. Typography and font styles were selected for simplicity and
              readability, but also for boldness.
            </p>
          </div>
          <div id="blank"></div>

          <div id="typo" className="mt-5">
            <h3 className="mb-5">Typeface</h3>
            <img
              src={btTypography1}
              width="50%"
              alt="Screenshots of typeface."
            />
            <img
              src={btTypography2}
              width="50%"
              alt="Screenshots of typeface."
            />
          </div>

          <div id="typoHeading">
            <h3 className="mb-5 mt-5">Styles</h3>
            <img src={btStyles} alt="Screenshots of font styles." />
          </div>
          <div id="comp">
            <h3 className="mb-5">Colors</h3>
            <img
              src={btColors}
              width="100%"
              alt="Screenshots of colors. Yellow, black, gray, and white."
            />
          </div>

          <div id="button">
            <h3 className="mb-5 mt-5">Buttons</h3>
            <img src={btButtons} width="70%" alt="Screenshots of buttons." />
          </div>
        </div>
      </div>

      {/* ORGANIZING PRODUCTS OVERVIEW */}

      <div className="project__details-container">
        <div className="row mb-5">
          <div className="col-12 col-sm-12 col-md-6 col-lg-5"></div>

          <div className="col-12 col-sm-12 col-md-6 col-lg-7 pb-5">
            <h4 className="mb-3">Getting Organized</h4>
            <p>
              There was a long list of products available to be customized. I
              organized them into specific categories to make it easier for the
              User to find their next favorite item.
            </p>
          </div>
        </div>

        <img
          src={btOrganizingProducts}
          width="100%"
          alt="Website navigation, displaying list of products in specific categories."
        />
      </div>

      {/* LAYING DOWN THE DESIGN */}
      <div id="mainContainer" className="project__layout-container clearfix">
        <div id="layoutContainer" className="project__layout-container-inner">
          <div id="layoutContent" className="project__layout-desc">
            <div
              id="description"
              className="project__layout-content-inner project__bolivar-layout-content-inner"
            >
              <div>
                <h4 className="mb-3">Laying Down the UI</h4>
                <p>
                  After sketching out a rough wireframe of the main pages, I
                  used Adobe XD to design the website. Once the client was
                  excited about the look, I got to work implementing the design
                  and developing the website using the plugins I had installed
                  and configured. Functionality was added to allow the User to
                  search for products by name or category, filter by price, add
                  an item to a wish list, and more.
                </p>
              </div>
            </div>
          </div>

          <div
            id="layoutImages"
            className="project__container-images project__bolivar-container-images"
          >
            <img
              src={btHome}
              className="img-fluid mb-5 project__images"
              alt="Bolivar Threads home page."
            />
            <img
              src={btShop}
              className="img-fluid mb-5 project__images"
              alt="Bolivar Threads Shop page."
            />
            <img
              src={btProduct}
              className="img-fluid mb-5 project__images"
              alt="Bolivar Threads single product page."
            />
            <img
              src={btAbout}
              className="img-fluid mb-5 project__images"
              alt="Bolivar Threads About page."
            />
          </div>
        </div>
      </div>

      {/* DESIGN DETAILS */}
      <div className="project__details-container row align-content-center pt-5 pb-5 ">
        <div
          className="
          col-sm-12 col-md-6 col-lg-6
          order-sm-2 order-md-1 order-lg-1
        "
        ></div>
        <div
          className="
          col-sm-12 col-md-6 col-lg-6
          order-sm-1 order-md-2 order-lg-2
        "
        >
          <h4 className="mb-3">Customizing Products</h4>
          <p>
            Not only are Users able to purchase products, they can add their own
            personal touches to them. After customizing the Fancy Product
            Designer plugin to fit the needs of the client, the User is easily
            able to place text on the item, select the font and thread color. An
            image of the customized product is then sent to Bolivar Threads to
            have the item monogrammed before being shipped off.
          </p>
        </div>
      </div>

      {/* ADDITIONAL PROJECT IMAGES */}
      <div
        className="project__details-container project__additional-container row align-items-center d-flex g-5"
        style={{
          // minHeight: "100vh",
          minHeight: "-webkit-fill-available",
          marginBottom: "100px",
        }}
      >
        <div className="col-sm-12 col-md-9 col-lg-9" style={{ zIndex: "10" }}>
          <img
            src={btCustomizeBag}
            className="img-fluid w-100 mb-5 project__images"
            alt="A view of the Fancy Product Designer interface, featuring a laptop case that is being customized."
          />
        </div>
        <div
          className="col-sm-12 col-md-3 col-lg-3 d-flex align-items-center"
          style={{ zIndex: "10" }}
        >
          <img
            src={btTextLayers}
            className="img-fluid mb-5 project__images"
            alt="Form that allows the User to enter text, select a font, and pick a thread color."
          />
        </div>

        <img
          src={abstractGridDashedLines}
          alt="Abstract of grid with dashed lines."
          className="project__additional-abstract-image"
        />
      </div>

      {/* MORE PROJECTS CAROUSEL */}
      <ProjectsCarousel proProjectList={proProjectList} isMobile={isMobile} />

      {/* END */}
    </div>
  );
}
