import React from "react";
import abstractGridLines from "../../../assets/images/abstract-gridLines.png";
import selfie from "../../../assets/images/abstract-js.png";
import resumePDF from "../../../assets/pdfs/Samborski_Juanita-Resume-UXUI.pdf";

export default function About() {
  return (
    <section id="about" className="about__container anchor">
      {/* Abstract Images */}
      <div className="about__container-background">
        <img
          src={abstractGridLines}
          alt="Abstract grid with vertical lines."
          width="400px"
          height="auto"
        />
      </div>

      {/* About Content and Image */}
      <div className="container">
        <div className="row about__row">
          <div
            className="
                col-lg-6 col-md-6 col-sm-12 col-12 
                order-lg-1 order-md-1 order-sm-2 order-2"
          >
            <p>
              I am a UX/UI Designer and Front-End Developer with a deep passion
              for creating intuitive, user-centered designs and seamless digital
              experiences. My curiosity drives me to explore new ways to craft
              bold, functional interfaces and develop websites that offer both
              optimal performance and user-friendliness.
            </p>
            <p>
              Since 2016, I have partnered with over 40 businesses to design and
              develop websites and applications that streamline processes, provide valuable
              functionality, and deliver exceptional user experiences.
            </p>
            <p>
              In 2022, I joined the Portland Trail Blazers as a UX/UI Designer
              and Front-End Developer, where I designed and built custom tools for
              basketball operations, ensuring they had the right solutions to meet their needs and enhance their workflows.
            </p>
          </div>
          <div
            className="
                col-lg-6 col-md-6 col-sm-12 col-12 
                order-lg-2 order-md-2 order-sm-1 order-1"
          >
            <div>
              <img
                src={selfie}
                alt="Juanita Samborski looking over shoulder at the river."
                width="400px"
                height="auto"
              />
            </div>
            <div className="primary-button__container about__resume-button" >
              <hr className="primary-button__line" />
              <a
                href={resumePDF}
                download="Samborski Resume"
                rel="noreferrer"
                target="_blank"
              >
                <button className="primary-button">View Resume</button>
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Scroll to Project Button */}
      <div className="borderless-button__container project-scroll">
        <hr className="borderless-button__line" />
        <button className="borderless-button">Projects</button>
      </div>
    </section>
  );
}
