import wordpressIcon from "../assets/icons/wordpress.png";
import htmlIcon from "../assets/icons/html.png";
import css3Icon from "../assets/icons/CSS3.png";
import bootstrapIcon from "../assets/icons/bootstrap.png";
import githubIcon from "../assets/icons/github.png";
import reactIcon from "../assets/icons/react.png";
import adobeXdIcon from "../assets/icons/xd-2x.png";
import illustratorIcon from "../assets/icons/illustrator-2x.png";
import photoshopIcon from "../assets/icons/photoshop-2x.png";
import indesignIcon from "../assets/icons/indesign-2x.png";
import javascriptIcon from "../assets/icons/javascript.png";
import nodejsIcon from "../assets/icons/nodejs.png";
import expressjsIcon from "../assets/icons/expressjs.png";
import mysqlIcon from "../assets/icons/mysql.png";
import mongodbIcon from "../assets/icons/mongoDB.png";
import typescript from "../assets/icons/typescript-logo.png";
import mui from "../assets/icons/mui-logo.png";
import sass from "../assets/icons/sass-logo.png";
import storybook from "../assets/icons/storybook-logo.png";
import asanaIcon from "../assets/icons/asana.png";
import elementorIcon from "../assets/icons/elementor.png";
import figmaIcon from "../assets/icons/figma.png";

const toolkitIcons = [
  {
    id: 1,
    title: "React",
    altText: "React Icon",
    image: reactIcon,
  },
  {
    id: 2,
    title: "JavaScript",
    altText: "JavaScript Icon",
    image: javascriptIcon,
  },
  {
    id: 3,
    title: "Typescript",
    altText: "Typescript Icon",
    image: typescript,
  },
  {
    id: 4,
    title: "HTML",
    altText: "HTML Icon",
    image: htmlIcon,
  },
  {
    id: 5,
    title: "Sass",
    altText: "Sass Icon",
    image: sass,
  },
  {
    id: 6,
    title: "CSS",
    altText: "CSS Icon",
    image: css3Icon,
  },
  {
    id: 7,
    title: "MUI",
    altText: "MUI Icon",
    image: mui,
  },
  {
    id: 8,
    title: "Bootstrap",
    altText: "Bootstrap Icon",
    image: bootstrapIcon,
  },
  {
    id: 9,
    title: "Storybook",
    altText: "Storybook Icon",
    image: storybook,
  },
  {
    id: 10,
    title: "Github",
    altText: "Github Icon",
    image: githubIcon,
  },
  {
    id: 11,
    title: "Figma",
    altText: "Figma Icon",
    image: figmaIcon,
  },
  {
    id: 12,
    title: "Adobe XD",
    altText: "Adobe XD Icon",
    image: adobeXdIcon,
  },
  {
    id: 13,
    title: "Adobe Illustrator",
    altText: "Adobe Illustrator Icon",
    image: illustratorIcon,
  },
  {
    id: 14,
    title: "Adobe Photoshop",
    altText: "Adobe Photoshop Icon",
    image: photoshopIcon,
  },
  {
    id: 15,
    title: "Adobe InDesign",
    altText: "Adobe InDesign Icon",
    image: indesignIcon,
  },
  {
    id: 16,
    title: "Node JS",
    altText: "Node JS Icon",
    image: nodejsIcon,
  },
  {
    id: 17,
    title: "Express JS",
    altText: "Express JS Icon",
    image: expressjsIcon,
  },
  {
    id: 18,
    title: "MySQL",
    altText: "MySQL Icon",
    image: mysqlIcon,
  },
  {
    id: 19,
    title: "Mongo DB",
    altText: "Mongo DB Icon",
    image: mongodbIcon,
  },
  {
    id: 20,
    title: "Asana",
    altText: "Asana Icon",
    image: asanaIcon,
  },
  {
    id: 21,
    title: "WordPress",
    altText: "WordPress Icon",
    image: wordpressIcon,
  },
  {
    id: 22,
    title: "Elementor",
    altText: "Elementor Icon",
    image: elementorIcon,
  },
];

export default toolkitIcons;
