import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { Offcanvas } from "react-bootstrap";

// Components
import Home from "./pages/home/Home";
import Project from "./pages/Projects";
import Sidebar from "./components/SidebarMain";
import ContactMain from "./components/ContactMain";
import FooterMain from "./components/FooterMain";
import TriangleMenu from "./pages/home/components/TriangleMenu";

// Stylesheet
import "./App.css";

// Project Pages
import Ano from "./projects/Ano";
import Bolivar from "./projects/Bolivar";
import FileEvictions from "./projects/FileEvictions";
import TrailBlazers from "./projects/TrailBlazers";

function App() {
  let location = useLocation();

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setTimeout(() => setShow(false), 1000);

  useEffect(() => {
    console.log(show);
  }, [show]);

  return (
    <div>
      <TriangleMenu handleShow={handleShow} />

      {location.pathname === "/" ? null : <Sidebar />}

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/projects" element={<Project />} />
        <Route path="/project/ano-delivery" element={<Ano />} />
        <Route path="/project/bolivar-threads" element={<Bolivar />} />
        <Route path="/project/file-evictions" element={<FileEvictions />} />
        <Route path="/project/trail-blazers" element={<TrailBlazers />} />
      </Routes>
      {location.pathname === "/" ? null : <ContactMain />}
      {location.pathname === "/" ? null : <FooterMain />}

      {/* Offcanvas menu triggered by TriangleMenu */}
      <Offcanvas
        show={show}
        onHide={handleClose}
        className="sidebar__offcanvas-start"
      >
        <Offcanvas.Header>
          <div
            onClick={handleClose}
            className="borderless-button__container sidebar__close-menu"
          >
            <hr className="borderless-button__line" />
            <button className="borderless-button">Close</button>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <nav>
            <ul className="sidebar__menu-items-list">
              <li onClick={handleClose}>
                <a href="/#home">
                  <h2>Home</h2>
                </a>
              </li>
              <li onClick={handleClose}>
                <a href="/#about">
                  <h2>About</h2>
                </a>
              </li>
              <li onClick={handleClose}>
                <a href="/#proProjects">
                  <h2>Client Projects</h2>
                </a>
              </li>
              <li onClick={handleClose}>
                <a href="/#toolkit">
                  <h2>Toolkit</h2>
                </a>
              </li>
              {/* <li onClick={handleClose}>
                    <a href="/#personalProjects">
                      <h2>Personal Projects</h2>
                    </a>
                  </li> */}
              <li onClick={handleClose}>
                <a href="/#contact">
                  <h2>Contact</h2>
                </a>
              </li>
            </ul>
          </nav>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

export default App;
