import React from "react";
import abstractGridLines from "../../../assets/images/abstract-gridLines.png";
import gitHubIcon from "../../../assets/icons/github-white-2x.png";
import linkedinIcon from "../../../assets/icons/linkedin-white-2x.png";
import emailIcon from "../../../assets/icons/email-white-2x.png";
import upworkIcon from "../../../assets/icons/upwork-tile-white.png";

export default function Contact() {
  return (
    <section id="contact" className="contactHome__main-container anchor">
      {/* Abstract Images */}
      <img
        src={abstractGridLines}
        alt="Abstract grid with vertical lines."
        width="400px"
        height="auto"
        className="contact__container-background-image"
      />

      {/* Contact Section */}
      <section className="contactHome__container">
        <div className="row g-5 d-flex align-items-center">
          <div className="col-sm-12 col-md-12 col-lg-6">
            <h3>Contact</h3>
            <h2>Let's create something together...</h2>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-6">
            <div>
              <a
                href="mailto:juanita.samborski@gmail.com"
                role="button"
                className="d-flex justify-content-between align-items-center contact-links"
              >
                Email
                <i className="bi bi-arrow-right contact-links-arrow"></i>
              </a>
              <hr className="contact-links-divider" />
              <a
                href="https://github.com/jsamborski310"
                rel="noreferrer"
                target="_blank"
                role="button"
                className="d-flex justify-content-between align-items-center contact-links"
              >
                Github
                <i className="bi bi-arrow-right contact-links-arrow"></i>
              </a>
              <hr className="contact-links-divider" />
              <a
                href="https://www.linkedin.com/in/juanita-samborski/"
                rel="noreferrer"
                target="_blank"
                role="button"
                className="d-flex justify-content-between align-items-center contact-links"
              >
                Linkedin
                <i className="bi bi-arrow-right contact-links-arrow"></i>
              </a>
              <hr className="contact-links-divider" />
              <a
                href="https://bit.ly/samborski-upwork"
                rel="noreferrer"
                target="_blank"
                role="button"
                className="d-flex justify-content-between align-items-center contact-links"
              >
                Upwork
                <i className="bi bi-arrow-right contact-links-arrow"></i>
              </a>
              <hr className="contact-links-divider" />
            </div>
          </div>
        </div>
      </section>

      {/* Footer */}
      <section className="row contact__home-footer-container">
        <div className="col-10 contact__home-footer">
          <p>Copyright Ⓒ 2016-2025. All Rights Reserved.</p>

          <div className="contact__menu-footer-icons">
            <a
              href="https://github.com/jsamborski310"
              rel="noreferrer"
              target="_blank"
            >
              <img
                src={gitHubIcon}
                alt="Github Icon."
                className="sidebar__menu-contact-icon"
              />
            </a>

            <a
              href="https://www.linkedin.com/in/juanita-samborski/"
              rel="noreferrer"
              target="_blank"
            >
              <img
                src={linkedinIcon}
                alt="Linkedin Icon."
                className="sidebar__menu-contact-icon"
              />
            </a>

            <a href="mailto:juanita.samborski@gmail.com">
              <img
                src={emailIcon}
                alt="Email Icon."
                className="sidebar__menu-contact-icon"
              />
            </a>

            <a
              href="https://bit.ly/samborski-upwork"
              rel="noreferrer"
              target="_blank"
            >
              <img
                src={upworkIcon}
                alt="UpWork Icon."
                className="sidebar__menu-contact-icon"
              />
            </a>
          </div>
        </div>
      </section>
    </section>
  );
}
