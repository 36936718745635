import React, { useEffect } from "react";
import proProjectList from "../data/proProjectsList";
import useMediaQuery from "../hooks/useMediaQuery";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import ProjectsCarousel from "../pages/home/components/ProProjectsCarousel";
import toolsIcons from "../data/toolkit";

// Images
import fileevictionsBanner from "../assets/images/FileEvictions-Welcome.png";
import abstractSquaredGrid from "../assets/images/square-squared-grid.png";
import abstractPlusGrid from "../assets/images/abstract-plus-grid-2.png";
import feHome from "../assets/images/FileEvictions-Home-2.png";
import feHelp from "../assets/images/FileEvictions-Help.png";
import feUpcoming from "../assets/images/FileEvictions-Upcoming.png";
import feSketch from "../assets/images/FileEvictions-sketch.jpg";
import feTasks from "../assets/images/FileEvictions-Tasks.png";
import feMessages from "../assets/images/FileEvictions-Messages.png";
import feAgreement from "../assets/images/FileEvictions-Attorney-Agreement.png";
import feAttorneyProfile from "../assets/images/FileEvictions-Attorney-Profile.png";
import feDesignThinking from "../assets/images/FileEvictions-design-thinking.png";
import feEmpathyMap from "../assets/images/FileEvictions-empathy-map.png";
import feCompetitiveAnalysis from "../assets/images/FileEvictions-competitive-analysis.png";
import feUserFlow from "../assets/images/FileEvictions-user-flow.png";
import feUserPersona from "../assets/images/FileEvictions-user-persona.png";
import feStyleGuide1 from "../assets/images/FileEvictions-style-guide-1.png";
import feStyleGuide2 from "../assets/images/FileEvictions-style-guide-2.png";
import fePrototype from "../assets/images/FileEvictions-prototype.png";
import feClientList from "../assets/images/FileEvictions-Client-List.png";
import feClientSettings from "../assets/images/FileEvictions-Client-Settings.png";

export default function FileEvictions() {
  // TOOLS USED
  const projectTools = [
    "Adobe XD",
    "Adobe Photoshop",
    "Adobe Illustrator",
    "HTML",
    "CSS",
    "WordPress",
    "Elementor",
    "Asana",
  ];

  const getToolsIcons = (tools) => {
    return toolsIcons.filter((icon) => tools.includes(icon.title));
  };

  const projectIcons = getToolsIcons(projectTools);

  // FOR LAYING DOWN THE UI
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    ScrollTrigger.matchMedia({
      "(min-width: 768px)": function () {
        gsap.to("#layoutImages", {
          scrollTrigger: {
            trigger: "#layoutImages",
            start: "top top",
            // pin: ".project__inner-layout-container",
            end: "bottom bottom",
            scrub: true,
            nullTargetWarn: false,
            // markers: "true",
          },
        });
      },
    });
  }, []);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    ScrollTrigger.matchMedia({
      "(min-width: 768px)": function () {
        gsap.to("#layoutImages", {
          scrollTrigger: {
            trigger: "#layoutImages",
            start: "top top",
            pin: "#layoutContent",
            end: "bottom bottom",
            scrub: true,
            nullTargetWarn: false,
            // markers: "true",
          },
        });
      },
    });
  }, []);

  const isMobile = useMediaQuery("(min-width:991px)");

  return (
    <div>
      {/* HEADER */}
      <section className="project__container">
        <img
          className="project__main-image"
          src={fileevictionsBanner}
          alt="Vector of man filling out an application."
        />
        <img
          className="project__main-image-abstract"
          src={abstractSquaredGrid}
          alt="Abstract. Grid of squares."
        />

        <div className="project__main-info d-flex align-items-center">
          <p className="project__main-project-number">02</p>
          <h1 className="project__main-title">FileEvictions</h1>
        </div>
      </section>

      {/* PROJECT OVERVIEW */}
      <div className="project__details-container">
        <div className="row g-5 pb-5">
          <div className="col-12 col-sm-12 col-md-5 col-lg-5">
            <div className="project__details-section">
              <h4>Client</h4>
              <p>FileEvictions</p>
            </div>
            <div className="project__details-section">
              <h4>Business Type</h4>
              <p>SaaS for Evictions</p>
            </div>
            <div className="project__details-section">
              <h4>Project Duration</h4>
              <p>2019-2022</p>
            </div>
            <div className="project__details-section">
              <h4>Status</h4>
              <p>Company website is complete. SaaS is in development.</p>
            </div>
          </div>

          <div className="col-12 col-sm-12 col-md-7 col-lg-7">
            <p>
              The client, a seasoned landlord, faced the challenges of managing
              evictions—high attorney costs, complex legal processes, and delays
              in court that led to financial losses. He wanted a solution to
              streamline the eviction process and help landlords like him
              navigate these challenges.
            </p>
            <p>
              Initially, the client envisioned a simple website that would allow
              users to complete forms, generating the necessary legal documents
              for evictions in New Jersey. However, as the project evolved, it
              became clear that a more sophisticated solution was required to
              meet both user and business needs.
            </p>
          </div>
        </div>

        {/* CONTRIBUTIONS */}
        <div className="row d-flex justify-content-end mt-5 mb-5 project__contribution-container">
          <img
            src={abstractPlusGrid}
            alt="Grid of plus symbol."
            className="project__contribution-image"
          />

          <div className="col-12 col-sm-12 col-md-12 col-lg-9">
            <h4 className="mb-5">Contributions</h4>

            <div className="row g-5 pt-5">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 project__contributions-item">
                <p>UX/UI Design for SaaS</p>
                <hr />
                <p>Website Design</p>
                <hr />
                <p>WordPress Development</p>
                <hr />
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 project__contributions-item">
                <p>HTML & CSS</p>
                <hr />
                <p>Project Management</p>
                <hr />
                <p>Content Writing</p>
                <hr />
              </div>
            </div>

            <h4 className="mt-5 mb-5">Environment & Tools</h4>
            <ul className="project__tool-list-container project__icons-tool-list">
              {projectIcons.map((icon, index) => (
                <img
                  key={index}
                  src={icon.image}
                  alt={icon.title}
                  className="project__tool-icon"
                />
              ))}
            </ul>
          </div>
        </div>

        {/* THE CHALLENGE */}
        <div className="row mt-5 pt-5 pb-5">
          <h4 className="mb-3">The Challenge</h4>
          <p>
            After several discussions with the client and extensive research, it
            became apparent that the scope of the project far exceeded the
            initial idea of a simple forms-based website. There were additional
            considerations such as differentiating between the needs of
            landlords and attorneys, handling complex legal workflows, and
            ensuring compliance with state laws. We also wanted to provide
            organizational tools to help users track cases and
            manage the legal process more efficiently.
          </p>
          <p>
            The original plan to create a basic WordPress site with a forms
            plugin was no longer a suitable solution due to its limitations. A
            custom solution was required to provide a more flexible and scalable
            platform that could address both immediate and future needs, as well
            as streamline case management.
          </p>
        </div>

        {/* PROJECT SCOPE */}
        <div className="row pb-5">
          <h4 className="mb-3">Project Scope</h4>
          <p>
            I was responsible for the complete UX/UI design and development of
            two websites:
          </p>
          <div className="px-5">
            <p>
              <strong>WordPress Website:</strong> This site provides users with
              information about the company, the services offered through the
              dashboard, and details about the eviction process.
            </p>

            <p>
              <strong>Web Application:</strong> A custom platform that allows landlords and attorneys to manage the
              eviction process through a dashboard. Users can generate legal
              documents, track case progress via tasks, calendar, and automated
              messages, and more.
            </p>
          </div>
          <p>
            Additionally, I managed the entire project, overseeing the
            development process with a team of developers to ensure the application functioned seamlessly and aligned with the initial design
            vision.
          </p>
        </div>
      </div>

      {/* USER-CENTERED DESIGN PROCESS */}
      <div className="project__details-container row align-items-end">
        <div className="col-12 col-sm-12 col-md-3 col-lg-3"></div>
        <div className="col-12 col-sm-12 col-md-9 col-lg-9 mb-5 p-0">
          <h4 className="mb-3">User-Centered Design Process</h4>
          <p>
            To ensure the design was user-centered, I employed a combination of{" "}
            <strong>Design Thinking</strong>, <strong>User Personas</strong>,
            and <strong>Empathy Mapping</strong>. Design Thinking allowed me to
            deeply understand user needs, identify opportunities for innovation,
            and iteratively refine solutions. User Personas were created to
            represent the key target users—landlords and attorneys—outlining
            their goals, challenges, and behaviors, while the Empathy Map
            provided deeper insights into their frustrations, emotions, and
            needs. Together, these tools informed every stage of the design
            process, ensuring the final product was aligned with user
            expectations.
          </p>
        </div>

        <img
          src={feDesignThinking}
          className="mb-5"
          alt="Design Thinking Process."
        />
        <img src={feUserPersona} className="mb-5" alt="User Persona." />
        <img src={feEmpathyMap} className="mb-5" alt="Empathy Map." />
      </div>

      {/* COMPETITIVE ANALYSIS */}
      <div className="project__details-container row align-items-end">
        <div className="col-12 col-sm-12 col-md-3 col-lg-3"></div>
        <div className="col-12 col-sm-12 col-md-9 col-lg-9 mb-5 p-0">
          <h4 className="mb-3">Competitive Analysis</h4>
          <p>
            Evaluating key competitors helped identify strengths, weaknesses,
            and opportunities for differentiation. By understanding the market
            landscape, I was able to better position the product to address
            unmet needs and offer unique value to users.
          </p>
        </div>

        <img
          src={feCompetitiveAnalysis}
          alt="A chart detailing features available through competitors."
        />
      </div>

      {/* USER FLOW */}
      <div className="project__details-container row align-items-end">
        <div className="row mt-5 mb-5">
          <div className="col-12 col-sm-12 col-md-3 col-lg-3"></div>
          <div className="col-12 col-sm-12 col-md-9 col-lg-9 mb-5 p-0">
            <h4 className="mb-3">User Flow</h4>
            <p>
              The user flow for this project is quite extensive. The image
              provided shows a truncated version of the process, focusing on a
              few key steps. In the complete flow, landlords could either
              self-file or hire an attorney, and there was also a separate
              dashboard for attorneys, each with distinct features and
              workflows. Additionally, each legal document in the process had
              its own detailed user flow.
            </p>
          </div>

          <img src={feUserFlow} alt="A shortened version of the User Flow." />
        </div>
      </div>

      {/* STYLE GUIDE */}
      <div className="project__details-container row align-items-end">
        {/* <div className="row mt-5 mb-5"> */}
        <div className="col-12 col-sm-12 col-md-3 col-lg-3"></div>
        <div className="col-12 col-sm-12 col-md-9 col-lg-9 mb-5 p-0">
          <h4 className="mb-3">Style Guide</h4>
          <p>
            To ensure design consistency across the dashboard, I developed a
            comprehensive style guide that included colors, typography, and
            reusable UI components. This guide not only facilitated a unified
            look and feel throughout the dashboard but also streamlined the
            development process by providing a clear reference for the
            development team, ensuring consistency across both the UI and
            codebase.
          </p>
        </div>

        <img
          src={feStyleGuide1}
          alt="FileEvictions Style Guide focusing on Typography and Colors."
        />
        <img
          src={feStyleGuide2}
          alt="FileEvictions Style Guide focusing on components."
        />
        {/* </div> */}
      </div>

      {/* INITIAL CONCEPT AND SKETCHES */}
      <div className="project__details-container row align-items-end">
        <div className="row mt-5 mb-5">
          <div className="col-12 col-sm-12 col-md-3 col-lg-3"></div>
          <div className="col-12 col-sm-12 col-md-9 col-lg-9 mb-5 p-0">
            <h4 className="mb-3">Initial Concept & Sketches</h4>
            <p>
              Before moving into high-fidelity design, I began by sketching
              rough layouts to visualize the user interface and overall flow.
              These sketches served as a starting point for design iterations,
              enabling us to discuss key features and user needs in detail
              before refining the design further in Adobe XD.
            </p>
          </div>

          <img
            src={feSketch}
            alt="FileEvictions Style Guide focusing on Typography and Colors."
          />
        </div>
      </div>

      {/* PROTOTYPE */}
      <div className="project__details-container row align-items-end">
        <div className="col-12 col-sm-12 col-md-3 col-lg-3"></div>
        <div className="col-12 col-sm-12 col-md-9 col-lg-9 mb-5 p-0">
          <h4 className="mb-3">Wireframes & Prototype Testing</h4>
          <p>
          During the design process, I created wireframes and prototyped them to validate the user flow, ensure usability, and identify areas for improvement. This iterative process allowed us to spot missing features or design inconsistencies, helping refine the user experience before moving into high-fidelity development. Prototyping also provided valuable insights into the overall interaction, ensuring the final product was intuitive and aligned with user needs.
          </p>
        </div>

        <img src={fePrototype} alt="FileEvictions prototype of four screens." />
      </div>

      {/* HIGH FIDELITY WIREFRAMES */}
      <div className="project__details-container row align-items-end">
        <div className="row mt-5 mb-3">
          <div className="col-12 col-sm-12 col-md-3 col-lg-3"></div>
          <div className="col-12 col-sm-12 col-md-9 col-lg-9 mb-3 p-0">
            <h4 className="mb-3">A Glimpse of the Dashboard</h4>
            <p>
              The high-fidelity wireframes for the dashboard illustrate the
              detailed UI design, including the color scheme, typography, and
              interactive elements. These wireframes showcase the final design
              direction, ensuring that both user experience and functionality
              were prioritized. They served as a guide for both the development
              team and the client, providing a clear picture of the dashboard’s
              layout and features.
            </p>
          </div>
        </div>
      </div>

      {/* DASHBOARD IMAGES */}
      <div className="project__details-container row align-content-center pb-5 g-5">
        <div className="col-sm-12 col-md-6 col-lg-6">
          <img
            src={feClientList}
            className="img-fluid w-100 mb-5 project__images"
            alt="FileEvictions Client List."
            style={{ boxShadow: "0 3px 50px #00000020" }}
          />
          <img
            src={feClientSettings}
            className="img-fluid w-100 mb-5 project__images"
            alt="FileEvictions Client Settings."
            style={{ boxShadow: "0 3px 50px #00000020" }}
          />
          <img
            src={feTasks}
            className="img-fluid w-100 mb-5 project__images"
            alt="FileEvictions Tasks page."
            style={{ boxShadow: "0 3px 50px #00000020" }}
          />
        </div>

        <div className="col-sm-12 col-md-6 col-lg-6 project__fileevictions-portal-layout">
          <img
            src={feMessages}
            className="img-fluid w-100 mb-5 project__images"
            alt="FileEvictions Messages page."
            style={{ boxShadow: "0 3px 50px #00000020" }}
          />
          <img
            src={feAttorneyProfile}
            className="img-fluid w-100 mb-5 project__images"
            alt="FileEvictions Attorney onboarding page. Profile section."
            style={{ boxShadow: "0 3px 50px #00000020" }}
          />
          <img
            src={feAgreement}
            className="img-fluid w-100 mb-5 project__images"
            alt="FileEvictions Attorney onboarding page. Attorney representation agreement section."
            style={{ boxShadow: "0 3px 50px #00000020" }}
          />
        </div>
      </div>

      {/* LAYING DOWN THE DESIGN */}
      <div id="mainContainer" className="project__layout-container clearfix">
        <div id="layoutContainer" className="project__layout-container-inner">
          <div id="layoutContent" className="project__layout-desc">
            <div id="description" className="project__layout-content-inner">
              <div>
                <h4 className="mb-3">UI and WordPress Development</h4>
                <p>
                  Given the substantial amount of content for the website, I
                  focused on organizing information to reduce cognitive load and
                  improve usability. Using methods like white space, accordions,
                  and clear navigation, I developed the WordPress site, which
                  serves as an informational hub about the eviction process and
                  the services provided.
                </p>
              </div>
            </div>
          </div>

          <div id="layoutImages" className="project__container-images">
            <img
              src={feHome}
              className="img-fluid mb-5 project__images"
              alt="FileEvictions Home page."
            />
            <img
              src={feHelp}
              className="img-fluid mb-5 project__images"
              alt="FileEvictions Help page."
            />
            <img
              src={feUpcoming}
              className="img-fluid mb-5 project__images"
              alt="FileEvictions Upcoming Features page."
            />
          </div>
        </div>
      </div>

      {/* PLACEHOLDER*/}
      <div className="project__details-container row align-content-center pt-5 pb-5 mt-5">
        {/* Placeholder to resolve layout issue. */}
      </div>

      {/* MORE PROJECTS CAROUSEL */}
      <ProjectsCarousel proProjectList={proProjectList} isMobile={isMobile} />

      {/* END */}
    </div>
  );
}
