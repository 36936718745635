import React, { useEffect } from "react";
import proProjectList from "../data/proProjectsList";
import useMediaQuery from "../hooks/useMediaQuery";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import ProjectsCarousel from "../pages/home/components/ProProjectsCarousel";
import toolsIcons from "../data/toolkit";

// Images
import components from "../assets/images/components.png";
import abstractSquaredGrid from "../assets/images/square-squared-grid.png";
import abstractPlusGrid from "../assets/images/abstract-plus-grid-2.png";
import tbBanner from "../assets/images/blazers_court.png";
import tbMidPageBanner from "../assets/images/Blazers-mid-page-banner.png";
import tbDesktop1 from "../assets/images/Blazers-desktop-1.png";
import tbDesktop2 from "../assets/images/Blazers-desktop-2.png";
import tbMobile from "../assets/images/Blazers-mobile-all.png";
import tbProjectManagementBoard from "../assets/images/Blazers-project_management_board.png";
import tbShaedonSharpe from "../assets/images/Blazers-shaedon-sharpe.png";
import tbInterview from "../assets/images/Blazers-interview-questions.png";
import tbUserFlow from "../assets/images/Blazers-user-flow.png";
import tbEmpathyMap from "../assets/images/Blazers-empathy-map.png";
import tbUserPersona from "../assets/images/Blazers-user-persona.png";
import tbDesignThinking from "../assets/images/Blazers-design-thinking.png";
import tbSergi from "../assets/images/Blazers-Sergi-Olivia.jpeg";
import tbQuote from "../assets/images/Blazers-quote.png";

export default function TrailBlazers() {
  // TOOLKIT
  const projectTools = [
    "React",
    "Javascript",
    "Typescript",
    "HTML",
    "CSS",
    "Saas",
    "MUI",
    "Storybook",
    "Github",
    "Adobe XD",
    "Photoshop",
    "Illustrator",
  ];

  const getToolsIcons = (tools) => {
    return toolsIcons.filter((icon) => tools.includes(icon.title));
  };

  const projectIcons = getToolsIcons(projectTools);

  // FOR LAYING DOWN THE UI
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    ScrollTrigger.matchMedia({
      "(min-width: 992px)": function () {
        gsap.to("#layoutImages", {
          scrollTrigger: {
            trigger: "#layoutImages",
            start: "top top",
            // pin: ".project__inner-layout-container",
            end: "bottom bottom",
            scrub: true,
            nullTargetWarn: false,
            // markers: "true",
          },
        });
      },
    });
  }, []);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    ScrollTrigger.matchMedia({
      "(min-width: 992px)": function () {
        gsap.to("#layoutImages", {
          scrollTrigger: {
            trigger: "#layoutImages",
            start: "top top",
            pin: "#layoutContent",
            end: "bottom bottom",
            scrub: true,
            nullTargetWarn: false,
            // markers: "true",
          },
        });
      },
    });
  }, []);

  const isMobile = useMediaQuery("(min-width:991px)");

  return (
    <div>
      {/* HEADER */}
      <section className="project__container">
        <img
          className="project__main-image"
          src={tbBanner}
          alt="Trail Blazers court during a basketball game."
        />
        <img
          className="project__main-image-abstract"
          src={abstractSquaredGrid}
          alt="Abstract. Grid of squares."
        />

        <div className="project__main-info d-flex align-items-center">
          <p className="project__main-project-number">01</p>
          <h1 className="project__main-title">Trail Blazers</h1>
        </div>
      </section>

      {/* PROJECT OVERVIEW */}
      <div className="project__details-container">
        <div className="row g-5 pb-5">
          <div className="col-12 col-sm-12 col-md-5 col-lg-5">
            <div className="project__details-section">
              <h4>Employer</h4>
              <p>Portland Trail Blazers</p>
            </div>
            <div className="project__details-section">
              <h4>Business Type</h4>
              <p>NBA Team</p>
            </div>
            <div className="project__details-section">
              <h4>Website</h4>
              <p>Exclusively for Basketball Operations</p>
            </div>
            <div className="project__details-section">
              <h4>Project Duration</h4>
              <p>2022-2024</p>
            </div>
          </div>

          <div
            className="col-12 col-sm-12 col-md-7 col-lg-7"
            style={{ marginTop: "5px" }}
          >
            <p
              style={{
                fontFamily: "'Square Peg', cursive",
                fontWeight: 400,
                fontStyle: "normal",
                textAlign: "right",
                fontSize: "40px",
              }}
            >
              "I don't watch basketball..."
            </p>
            <p>
              One morning, I received an email about a position with a sports
              team I hadn't heard of, in a sport I wasn't particularly
              interested in. At first, I wasn’t sure if it was the right fit,
              but after learning more about the organization's needs and the
              opportunity to help build a web application from the ground up, I
              became intrigued.
            </p>
            <p>
              As a UX/UI Designer and Front-end Developer, I was excited by the
              challenge. The Portland Trail Blazers were looking to develop
              tools that would automate and streamline workflows for basketball
              operations, including scouts, medical teams, and management. The
              opportunity to create something impactful was too good to pass up.
              Before I knew it, I was not only excited about the project, but
              also becoming a fan of the sport itself.
            </p>
          </div>
        </div>

        {/* CONTRIBUTIONS */}
        <div className="row d-flex justify-content-end mt-5 mb-5 project__contribution-container">
          <img
            src={abstractPlusGrid}
            alt="Grid of plus symbol."
            className="project__contribution-image"
          />

          <div className="col-12 col-sm-12 col-md-12 col-lg-9">
            <h4 className="mb-5">Contributions</h4>

            <div className="row g-5 pt-5">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 project__contributions-item">
                <p>UX Research</p>
                <hr />
                <p>UI Design</p>
                <hr />
                <p>Design System</p>
                <hr />
                <p>Graphic Design</p>
                <hr />
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 project__contributions-item">
                <p>Front-end development</p>
                <hr />
                <p>Component Library</p>
                <hr />
                <p>Project Management</p>
                <hr />
                <p>Content Creation</p>
                <hr />
              </div>
            </div>
            <h4 className="mt-5 mb-5">Environment & Tools</h4>
            <ul className="project__tool-list-container project__icons-tool-list">
              {projectIcons.map((icon, index) => (
                <img
                  key={index}
                  src={icon.image}
                  alt={icon.title}
                  className="project__tool-icon"
                />
              ))}
            </ul>
          </div>
        </div>

        {/* RESEARCH RAMP UP */}
        <div className="row mt-5 pt-5 pb-5">
          <h4 className="mb-3">Research: Diving Deep Into the Game</h4>
          <p>
            I immersed myself fully in the world of basketball, studying the
            sport, its games, scoring systems, and player stats. I watched live
            games, examined game highlights, and browsed fan forums and social
            media to understand fan expectations.
          </p>
          <p>
            Simultaneously, I reviewed the team’s existing legacy software and
            explored tools used by similar organizations.
          </p>
          <p>
            On day one, I grabbed a dry erase marker and started sketching user
            flows on the whiteboard as the Research and Development team
            discussed the needs and ideas for the scouting department. These
            early brainstorming sessions provided a wealth of insights, helping
            us better understand the requirements and pain points that would
            guide the design process.
          </p>
        </div>
      </div>

      {/* DISCLAIMER */}
      <div className="project__details-container row align-items-end mb-5">
        <div className="col-12 p-0">
          <h4 className="mb-3">A Quick Note...</h4>
          <p>
            All graphics on this page were created just for this case
            study—they’re not actual work products. Think of them as my creative
            playground. Actual sketches, wireframes, designs, and presentations
            are top-secret, hush-hush stuff to keep things confidential and
            protect the organization’s intellectual property. The info here
            covers basic design and development concepts and my role in the
            process.
          </p>

          <img
            src={tbMidPageBanner}
            alt="Portland Trail Blazers Logo with Background of Portland skyline."
            width={"100%"}
            className="mt-5"
            style={{ borderRadius: "20px" }}
          />
        </div>
      </div>

      {/* DESIGN THINKING */}

      <div className="project__details-container row align-items-end">
        <div className="col-12 col-sm-12 col-md-3 col-lg-3"></div>
        <div className="col-12 col-sm-12 col-md-9 col-lg-9 mb-5 p-0">
          <h4 className="mb-3">Design Thinking: Refining the Solution</h4>
          <p>
            From the insights gathered during research, I followed the design
            thinking process to ensure the solutions would truly meet the users’
            needs. I began by conducting interviews with stakeholders to
            pinpoint the specific challenges and goals.
          </p>
          <p>
            I then created user personas to represent the key end-users and
            developed a user journey to visualize how users would interact with
            the product from start to finish. These tools helped to identify
            pain points and opportunities for improvement, ensuring that the
            designs were both user-centered and functional.
          </p>
        </div>
        <img
          src={tbInterview}
          className="mb-5"
          alt="10 Initial Interview Questions."
        />
        <img
          src={tbDesignThinking}
          className="mb-5"
          alt="Design Thinking process."
        />
        <img
          src={tbUserPersona}
          className="mb-5"
          alt="Re-created User Persona."
        />
        <img src={tbEmpathyMap} className="mb-5" alt="Empathy Map." />
        <img src={tbUserFlow} className="mb-5" alt="User Flow graphic." />
      </div>

      {/* LAYING DOWN THE DESIGN */}
      <div
        id="mainContainer"
        className="project__blazers-layout-container clearfix"
      >
        <div
          id="layoutContainer"
          className="project__blazers-layout-container-inner"
        >
          <div id="layoutContent" className="project__blazers-layout-desc">
            <div
              id="description"
              className="project__blazers-layout-content-inner"
            >
              <div>
                <h4 className="mb-3">
                  Wireframing: Iterating Through Feedback
                </h4>
                <p>
                  With a clear understanding of user needs, I began producing
                  low-fidelity wireframes to establish the basic flow. Once the
                  flow was solidified, I progressed to high-fidelity wireframes,
                  refining the design further. This process involved multiple
                  iterations based on continuous feedback from users and
                  stakeholders, ensuring the designs aligned with both user
                  expectations and project goals.
                </p>
              </div>
            </div>
          </div>

          <div id="layoutImages" className="project__blazers-container-images">
            <img
              src={tbDesktop1}
              className="img-fluid mb-5 project__images"
              alt="Desktop wireframe."
            />
            <img
              src={tbDesktop2}
              className="img-fluid mb-5 project__images"
              alt="Desktop wireframe."
            />
            <img
              src={tbMobile}
              className="img-fluid mb-5 project__images"
              alt="Mobile wireframe."
            />
          </div>
        </div>
      </div>

      {/* COMPONENTS */}
      <div className="project__details-container row align-items-end">
        <div className="col-12 col-sm-12 col-md-3 col-lg-3 mb-5"></div>
        <div className="col-12 col-sm-12 col-md-9 col-lg-9 mb-5 p-0">
          <h4 className="mb-3">Design Realization: Bringing Ideas to Life</h4>
          <p>
            Once the high-fidelity wireframes were approved, I focused on
            bringing the designs to life. I was largely responsible for
            developing the front-end. I implemented Storybook to document and
            develop UI components. This tool allowed me to build components in
            isolation, iterate rapidly, and maintain consistency across the
            project.
          </p>
          <p>
            I collaborated closely with the development team, ensuring the
            seamless integration of my designs into the front-end. While I led
            the front-end development, other developers contributed to certain
            parts of the implementation process, helping to speed up
            development. The result was a cohesive, user-friendly interface that
            matched the original design vision while meeting the needs of the
            users.
          </p>
        </div>
        <div className="col-12 col-sm-12 col-md-3 col-lg-3"></div>

        <img
          src={components}
          alt="Wireframes of various components."
          width={"100%"}
        />
      </div>

      {/* PROJECT MANAGEMENT */}
      <div className="project__details-container row align-items-end">
        <div
          className="row mt-5 mb-5"
          style={{ paddingLeft: 0, paddingRight: 0 }}
        >
          <div className="col-12 mb-5">
            <h4 className="mb-3">Project Management: Organizing the Process</h4>
            <p>
              Recognizing the importance of a structured approach, I introduced
              project management practices to the development team. Initially, I
              organized my own tasks in Asana, creating detailed projects,
              setting deadlines, and tracking progress. This system kept me
              organized and helped the project move forward efficiently.
            </p>
            <p>
              As the team saw the value in having a clear structure, they became
              more involved, and we transitioned to using the GitHub Kanban
              board. I organized tasks into stories, sprints, and tags, making
              it easier for the team to collaborate, track progress, and stay
              aligned with development goals. This system improved our workflow
              and ensured we stayed focused on our objectives.
            </p>
          </div>

          <img
            src={tbProjectManagementBoard}
            alt="Wireframe of a project management board."
          />
        </div>
      </div>

      {/* GRAPHIC DESIGN */}
      <div className="project__details-container row pt-5 pb-5 mt-5 spacing-5">
        <div
          className="
          col-sm-12 col-md-12 col-lg-7
          order-2 order-md-2 order-lg-1
          blazers-sharpe-image
        "
        >
          <img
            src={tbShaedonSharpe}
            className="img-fluid w-100 mb-5 project__images"
            alt="Shaedon Sharpe player sheet with stats."
          />
        </div>
        <div
          className="
          col-sm-12 col-md-12 col-lg-5
          order-1 order-md-1 order-lg-2 d-flex flex-column justify-content-center pb-5 p-0
        "
        >
          <h4 className="mb-3">
            Crafting Engaging Visuals: Supporting the Team
          </h4>
          <p>
            In addition to the core application design, I applied my graphic
            design skills to create polished, professional materials for various
            departments, including coaching, scouting, and medical teams. From
            player sheets to presentations, I ensured every visual element was
            aligned with the project’s goals and needs, enhancing the
            professionalism and clarity of internal materials.
          </p>
        </div>
      </div>

      {/* TESTIMONIAL */}
      <div className="project__details-container row align-items-end mb-5">
        <div className="col-12 col-sm-12 col-md-3 col-lg-3 mb-5"></div>
        <div
          className="col-12 col-sm-12 col-md-9 col-lg-9 mb-5 p-0"
          style={{ position: "relative" }}
        >
          <h4 className="mb-3">Testimonial</h4>
          <p>
            Juanita was instrumental on the initial design of our internal
            online tools - making thorough tasks of data input or insight
            collection be seamless to our scouts and coaches.
          </p>
          <p>
            Her taste and proactiveness allowed our platform to grow beautifully
            and steadily - creating a place that all wanted to interact with,
            giving us a de facto corporate image for Basketball Operations in
            the process. Working with different roles across multiple
            departments, our presentation and consistency standards were raised
            immediately by her work - on our website, or in virtually any
            deliverable we produced.
          </p>
          <img
            src={tbQuote}
            className="mb-5"
            alt="Stylized quotation mark."
            style={{ position: "absolute", top: "-40px", left: "-40px" }}
          />
          <div
            style={{
              display: "inline-flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <img
              src={tbSergi}
              alt="Headshot of Sergi Olivia."
              style={{ borderRadius: "50px" }}
            />

            <p>
              Sergi Olivia
              <br />
              <em>Assistant General Manager of the Portland Trail Blazers</em>
            </p>
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-3 col-lg-3"></div>
      </div>

      {/* MORE PROJECTS CAROUSEL */}
      <ProjectsCarousel proProjectList={proProjectList} isMobile={isMobile} />

      {/* END */}
    </div>
  );
}
