import React, { useState, useEffect } from "react";
import menuIcon from "../../../assets/images/Menu-Icon.png";

const TriangleMenu = ({ handleShow }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={`triangle-menu-wrapper ${isVisible ? "visible" : "hidden"}`}
    >
      <div className="triangle-topright"></div>

      <button className="triangle-menu-button" onClick={handleShow}>
        <img
          src={menuIcon}
          alt="Hamburger Menu Icon."
          className="triangle-menu-icon"
          height="25px"
        />
      </button>
    </div>
  );
};

export default TriangleMenu;
